<template>
  <div class="flex-container non-scrollable">
    <SocietyDocumentsSharer/>
  </div>
</template>

<script>
import SocietyDocumentsSharer from "@/components/SocietyDocumentsSharer.vue";
import {mapGetters} from "vuex";

export default {
  name: "Library",
  components: {SocietyDocumentsSharer},
  computed: {
    ...mapGetters({
      me: 'auth/getMe',
    })
  },
}
</script>

<style scoped>

</style>
